<template>
    <div>
        <v-speed-dial
            class="mr-6 mt-n2"
            v-model="fab"
            absolute
            top
            right
            direction="bottom"
            transition="slide-y-reverse-transition"
        >
            <template v-slot:activator>
                <v-btn v-model="fab" color="warning darken-2" dark fab>
                    <v-icon v-if="fab"> mdi-close </v-icon>
                    <v-icon v-else> mdi-text-account </v-icon>
                </v-btn>
            </template>
            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        fab
                        dark
                        color="accent darken-1"
                        @click="fillDialog = true"
                        v-on="on"
                        v-bind="attrs"
                    >
                        <v-icon>mdi-file-account</v-icon>
                    </v-btn>
                </template>
                <span>Fill with Test Applicant</span>
            </v-tooltip>
            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        fab
                        dark
                        color="primary"
                        @click="viewDialog = true"
                        v-on="on"
                        v-bind="attrs"
                    >
                        <v-icon>mdi-file-eye</v-icon>
                    </v-btn>
                </template>
                <span>View Appllicant Details</span>
            </v-tooltip>
        </v-speed-dial>

        <v-dialog v-model="fillDialog" fullscreen persistent>
            <psi-dialog
                v-if="fillDialog"
                icon="mdi-account-box"
                title="Applicant Test Data"
                @close="fillDialog = false"
                hide-actions
            >
                <applicant-test-selector
                    @select:applicant="selectApplicant"
                ></applicant-test-selector>
            </psi-dialog>
        </v-dialog>

        <v-dialog v-model="viewDialog" fullscreen persistent>
            <psi-dialog
                v-if="viewDialog"
                icon="mdi-account-box"
                title="View Applicant Data"
                @close="viewDialog = false"
                hide-actions
            >
                <input
                    id="content"
                    type="hidden"
                    :value="JSON.stringify(applicant)"
                />
                <json-viewer
                    :value="applicant"
                    boxed
                    expanded
                    :expand-depth="5"
                ></json-viewer>
            </psi-dialog>
        </v-dialog>
        <v-snackbar v-model="snackbar" top> Content copied! </v-snackbar>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "applicant-test-toolbar",
    components: {
        ApplicantTestSelector: () => import("./ApplicantTestSelector"),
    },
    props: {},
    data() {
        return {
            fab: false,
            fillDialog: false,
            viewDialog: false,
            snackbar: false,
        };
    },
    computed: {
        ...mapGetters("Applications", ["applicant"]),
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        ...mapActions("Applications", ["fillApplicant"]),
        viewApplicant() {},
        selectApplicant(item) {
            this.fillApplicant(item.id);
            this.fillDialog = false;
        },
    },
};
</script>

<style scoped>
</style>
 
 