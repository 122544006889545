var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-speed-dial",
        {
          staticClass: "mr-6 mt-n2",
          attrs: {
            absolute: "",
            top: "",
            right: "",
            direction: "bottom",
            transition: "slide-y-reverse-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "warning darken-2", dark: "", fab: "" },
                      model: {
                        value: _vm.fab,
                        callback: function($$v) {
                          _vm.fab = $$v
                        },
                        expression: "fab"
                      }
                    },
                    [
                      _vm.fab
                        ? _c("v-icon", [_vm._v(" mdi-close ")])
                        : _c("v-icon", [_vm._v(" mdi-text-account ")])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.fab,
            callback: function($$v) {
              _vm.fab = $$v
            },
            expression: "fab"
          }
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: { left: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                fab: "",
                                dark: "",
                                color: "accent darken-1"
                              },
                              on: {
                                click: function($event) {
                                  _vm.fillDialog = true
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-file-account")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Fill with Test Applicant")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { left: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: { fab: "", dark: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.viewDialog = true
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-file-eye")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("View Appllicant Details")])]
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "", persistent: "" },
          model: {
            value: _vm.fillDialog,
            callback: function($$v) {
              _vm.fillDialog = $$v
            },
            expression: "fillDialog"
          }
        },
        [
          _vm.fillDialog
            ? _c(
                "psi-dialog",
                {
                  attrs: {
                    icon: "mdi-account-box",
                    title: "Applicant Test Data",
                    "hide-actions": ""
                  },
                  on: {
                    close: function($event) {
                      _vm.fillDialog = false
                    }
                  }
                },
                [
                  _c("applicant-test-selector", {
                    on: { "select:applicant": _vm.selectApplicant }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "", persistent: "" },
          model: {
            value: _vm.viewDialog,
            callback: function($$v) {
              _vm.viewDialog = $$v
            },
            expression: "viewDialog"
          }
        },
        [
          _vm.viewDialog
            ? _c(
                "psi-dialog",
                {
                  attrs: {
                    icon: "mdi-account-box",
                    title: "View Applicant Data",
                    "hide-actions": ""
                  },
                  on: {
                    close: function($event) {
                      _vm.viewDialog = false
                    }
                  }
                },
                [
                  _c("input", {
                    attrs: { id: "content", type: "hidden" },
                    domProps: { value: JSON.stringify(_vm.applicant) }
                  }),
                  _c("json-viewer", {
                    attrs: {
                      value: _vm.applicant,
                      boxed: "",
                      expanded: "",
                      "expand-depth": 5
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { top: "" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" Content copied! ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }